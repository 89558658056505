import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  FormControl,
  Grid,
  Input,
  VStack,
  theme,
} from '@chakra-ui/react';
import OpenAI from 'openai';
import React, { useEffect, useRef, useState } from 'react';

function App() {
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [promptInput, setPromptInput] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [answer, setAnswer] = useState('');
  const [openai, setOpenAiInstance] = useState(null);
  const [assistant, setAssistant] = useState(null);
  const inputRef = useRef();

  const initializeOpenAI = async () => {
    const newOpenAiInstance = new OpenAI({
      apiKey: apiKey,
      baseURL: process.env.REACT_APP_OPENAI_BASE_URL,
      dangerouslyAllowBrowser: true,
    });
    setOpenAiInstance(newOpenAiInstance);

    try {
      const createdAssistant = await newOpenAiInstance.beta.assistants.create({
        name: 'Math Tutor',
        instructions:
          'You are a personal math tutor. Write and run code to answer math questions.',
        tools: [{ type: 'code_interpreter' }],
        model: 'gpt-4o',
      });
      setAssistant(createdAssistant);
      setIsAuthorised(true);
    } catch (error) {
      console.error('Error creating assistant:', error);
      alert('Invalid API Key');
    }
  };

  const createThread = async () => {
    try {
      const thread = await openai.beta.threads.create();
      return thread;
    } catch (error) {
      console.error('Error creating thread:', error);
      return null;
    }
  };

  const createRun = async (assistant, thread) => {
    try {
      const run = openai.beta.threads.runs
        .stream(thread.id, {
          assistant_id: assistant.id,
          instructions: promptInput,
        })
        .on('textCreated', text => setAnswer(prev => prev + '\nassistant > '))
        .on('textDelta', (textDelta, snapshot) =>
          setAnswer(prev => prev + textDelta.value)
        )
        .on('toolCallCreated', toolCall =>
          setAnswer(prev => prev + `\nassistant > ${toolCall.type}\n\n`)
        )
        .on('toolCallDelta', (toolCallDelta, snapshot) => {
          if (toolCallDelta.type === 'code_interpreter') {
            if (toolCallDelta.code_interpreter.input) {
              setAnswer(prev => prev + toolCallDelta.code_interpreter.input);
            }
            if (toolCallDelta.code_interpreter.outputs) {
              setAnswer(prev => prev + '\noutput >\n');
              toolCallDelta.code_interpreter.outputs.forEach(output => {
                if (output.type === 'logs') {
                  setAnswer(prev => prev + `\n${output.logs}\n`);
                }
              });
            }
          }
        });

      return run;
    } catch (error) {
      console.error('Error creating run:', error);
      return null;
    }
  };

  const handleSubmitPrompt = async () => {
    if (!assistant) {
      console.error('Assistant not initialized');
      return;
    }

    try {
      const thread = await createThread();
      if (!thread) return;

      const run = await createRun(assistant, thread);

      if (run && run.status === 'completed') {
        const messages = await openai.beta.threads.messages.list(run.thread_id);
        for (const message of messages.data.reverse()) {
          setAnswer(
            prev => prev + `${message.role} > ${message.content[0].text.value}`
          );
        }
      } else {
        console.log(run ? run.status : 'Run not created');
      }
    } catch (error) {
      console.error('Error during prompt submission:', error);
    }
  };

  const handleLogin = async () => {
    if (apiKey.length === 0) {
      alert('API Key is required');
      return;
    }

    await initializeOpenAI();
  };

  const handlePressEnter = (e, type) => {
    if (e.key !== 'Enter') return;

    if (type === 'Login') {
      handleLogin();
    }

    if (type === 'Prompt') {
      handleSubmitPrompt();
    }
  };

  useEffect(() => {
    console.log('OpenAI instance:', openai);
    console.log('Assistant:', assistant);
  }, [openai, assistant]);

  if (!isAuthorised) {
    return (
      <Flex
        w={'100vw'}
        h={'100vh'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'16px'}
      >
        <Input
          bgColor={'#fff'}
          w={'60%'}
          onChange={e => setApiKey(e.target.value)}
          placeholder="API Key"
          onKeyDown={e => handlePressEnter(e, 'Login')}
        />
        <Button onClick={handleLogin} han>
          Login
        </Button>
      </Flex>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" bgColor={'#ecebf9'}>
        <Grid minH="100vh" p={3}>
          <VStack spacing={8}>
            <pre
              style={{
                width: '80vw',
                height: '50%',
                borderRadius: '16px',
                border: '1px solid gray',
                backgroundColor: '#fff',
                overflow: 'hidden',
                padding: '20px',
                textWrap: 'balance',
                textOverflowY: 'scroll',
              }}
            >
              {answer}
            </pre>

            <FormControl>
              <Input
                ref={inputRef}
                bgColor={'#fff'}
                w={'60%'}
                onKeyDown={e => handlePressEnter(e, 'Prompt')}
                onChange={e => setPromptInput(e.target.value)}
                placeholder="Type your prompt here"
                type="text"
              />

              <Button onClick={handleSubmitPrompt} color={'#000'}>
                Submit
              </Button>
            </FormControl>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
